/* istanbul ignore file */
'use client'

import { FC } from 'react'
import { ErrorPage } from '../../components/client'
import { useTranslation } from '../../lib/hooks'

// FIXME: Not working: https://github.com/vercel/next.js/discussions/57938
const NotFoundPage: FC = () => {
    const { t } = useTranslation()

    return (
        <ErrorPage
            title={t('common:errorPage.404')}
            homepageButton
            retryButton
        />
    )
}

export default NotFoundPage
